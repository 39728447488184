@media only screen and (min-width: 1200px) {
    
}
@media only screen and (min-width: 992px) {
    .login-body .sign-up-link{
        display: none;
    }
    
}
@media only screen and (max-width: 992px) {
    .login-body .container.right-panel-active .sign-up-container {
        transform: translateX(0);
        opacity: 1;
        z-index: 5;
        animation: show 0.6s;
    }
    .login-body .sign-up-container {
        left: 0;
        width: 100%;
        opacity: 0;
        z-index: 1;
    }
    .login-body .sign-in-container {
        left: 0;
        width: 100%;
        z-index: 2;
    }
    .login-body .overlay-container {
       display: none;
    }
    .login-body .container {
        width: 500px;
    }
    .login-body .sign-up-link{
        color:#2c3d4d;
        font-weight: 600;
        cursor: pointer;
    }
}
@media only screen and (min-width: 768px) {
   
}
@media only screen and (max-width: 600px) {
    .login-body .container {
        width: 400px!important;
        min-height: 420px;
    }
    .login-body .form {
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 30px;
        height: 100%;
        text-align: center;
    }
    .login-body .sign-up-head{
        font-size: 28px;
    }
}

@media only screen and (max-width: 380px) {
    .login-body .container {
        width: 300px!important;
    }
}








