* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif;
  font-size: 0.875rem;
}

.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.my-30 {
  margin: 30px 0;
}
.mb-30{
  margin-bottom: 30px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0e2e7d;
}
.primary {
  color: #0e2e7d;
}
p,
div {
  color: rgba(0, 0, 0, 0.87);
}
.img-fluid {
  width: 100%;
}
.padding-section {
  padding: 30px 0;
}
.table-responsive {
  width: 100%;
  display: block;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: calc(100% - 20px);
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0e2e7d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0e2e7d;
}
a {
  text-decoration: none !important;
}
.nav_logo {
  height: 35px;
  width: 35px;
}
.livelink {
  cursor: pointer;
  color: #0e2e7d;
}
.livelink:hover {
  background: #0e2e7d;
  color: #fff !important;
  border-radius: 5px !important;
}

.top-header header {
  border-bottom: 1px solid rgb(206, 206, 206);
  box-shadow: none;
  background: #fff !important;
}

.notification-dot {
  height: 6px;
  width: 6px;
  color: red;
  background: red;
  border-radius: 50%;
  position: relative;
  top: 12px;
}
.profile-area {
  color: #0e2e7d;
  text-align: end;
  padding-left: 10px;
}
.profile-area .company {
  font-size: 16px;
  font-weight: 400 !important;
}
.profile-area .member-name {
  font-size: 14px;
}
.sidebar_text span {
  font-size: 14px !important;
  color: #0e2e7d;
  text-transform: capitalize;
}

.list-area li:hover {
  background: #c0c5e1;
}
.list-area li.active {
  background: #c0c5e1;
}
.list-area li a.active {
  background: #c0c5e1;
}
.border-no .MuiDrawer-paperAnchorDockedLeft {
  border-right: none!important;
}
.main_menuicon {
  border-radius: 50%;
  border: 1px solid #0e2e7d;
  padding: 2px;
}
.inner-section {
  margin-top: 30px;
  padding: 0 30px;
  margin-bottom: 40px;
  min-height: calc(100vh - 185px);
  position: relative;
}
.upper-bluearea p{
  background: #0E2E7D;
  color:#fff!important;
}
.upper-bluearea p {
  color: #0e2e7d;
  padding: 0.75rem 1.25rem;
  text-align: center;
}
.main-section {
  background: #c0c5e1;
  padding: 0 !important;
}

.inner-section .incoming-funds {
  background: #fff;
  padding: 30px;
}

.inner-section .referral-code {
  margin: 20px 0 10px 0;
  background: #0e2e7d;
  color: #fff;
  text-transform: capitalize !important;
  font-weight: 400;
}
.inner-section .referral-code:hover {
  background: #000 !important;
}
.inner-section .incoming-funds .fund_head {
  font-size: 14px;
  font-weight: 500;
  color: #0e2e7d;
  padding-bottom: 20px !important;
}
.inner-section .dollarSection .dollar_text {
  font-size: 14px;
}
.inner-section .dollarSection .dollar_numbers {
  font-size: 25px;
  color: #0e2e7d;
  font-weight: 300;
}
.pagination_dashboard {
  text-align: end;
  display: flex;
  justify-content: end;
  padding: 15px 0;
}
.checkbox_table svg {
  height: 18px !important;
  width: 18px !important;
  accent-color: #0e2e7d !important;
}

.forever_table {
  border-radius: 0 !important;
}
.searchHeadTable {
  border: none !important;
}
.searchHeadTable input::placeholder {
  font-size: 14px !important;
  font-weight: 500;
  color:rgba(0, 0, 0, 0.87)!important;
}
.searchHeadTable fieldset {
  border: none !important;
}
.upperheader_forever {
  border-bottom: 1px solid #d4d4d4;
  padding: 0 20px !important;
  min-height: 56px !important;
}
.upperheader_forever .table-head-p {
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upperheader_forever .table-head-p:nth-child(1) {
  border-right: 1px solid #d4d4d4;
}
.upperheader_forever .table-head-p:nth-child(2) {
  border-right: 1px solid #d4d4d4;
}
.upperheader_forever .table-head-p.four {
  border-right: 1px solid #d4d4d4;
}
.upperheader_forever .table-head-p {
  font-size: 14px !important;
  color:rgba(0, 0, 0, 0.87);
}
.upperheader_forever .table-head-p.no-line:nth-child(2) {
  border-right: none;
}
.upperheader_forever .table-head-p.no-report-line:nth-child(1) {
  border-right: none;
}

.table_head_btns {
  padding: 10px 0;
}
.table_head_btns .table_inner_btn {
  border: 1px solid #6c757d;
  background-color: #e6e6e6;
  color: #6c757d;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}
.table_head_btns .table_inner_btn:hover {
  background: #0e2e7d;
  color: #fff;
}
.table-head-color th {
  color: #0e2e7d;
}
.users_marketplace .table_inner_btn.add_user {
  background-color: #0e2e7d;
  color: #fff;
  font-size: 13px;
}
.users_marketplace .table_inner_btn {
  font-size: 13px;
}
/* ------------send a payment----------------- */

.inner-section .payment_form_area .payment_form {
  padding: 25px;
  background-color: #fff;
}
.inner-section .payment_form_area .payment_input input {
  padding: 10px;
}
.inner-section .payment_form_area .payment_input input::placeholder {
  font-size: 14px;
}
.inner-section .payment_form_area .payment_input fieldset {
  border: none !important;
}
.inner-section .payment_form_area .icon_input {
  margin: 15px 0;
  display: flex;
  border: 1px solid rgba(224, 224, 224, 1);
}
.inner-section .payment_form_area .icon_input .icon_style {
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(224, 224, 224, 1);
  padding: 5px 15px;
}
.inner-section .payment_form_area .icon_input .icon_style i {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 30px;
  height: 30px;
  color: #0e2e7d;
  font-size: 14px;
  border: 1px solid #0e2e7d;
  border-radius: 50%;
}
.inner-section .payment_form_area .payment_toggle {
  margin: 15px 0;
  display: flex;
  align-items: center;
}
.inner-section .payment_form_area .payment_toggle p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.payment_bank {
  display: flex;
  justify-content: space-between;
}
.payment_bank .source {
  width: 320px;
  max-width: 100%;
  color: rgba(0, 0, 0, 0.87);
}
.payment_bank .bank_input .bank_select {
  height: 45px;
}
.payment_bank .bank_input input {
  padding: 10px !important;
}
.payment_bank .bank_input fieldset {
  border: 1px solid rgba(224, 224, 224, 1);
}
.inner-section .payment_form_area .payment_form .attach_file fieldset {
  border: none !important;
}
.payment_form .attach_file input {
  padding: 10px !important;
  border: 1px solid rgba(224, 224, 224, 1) !important;
}
.payment_form .attach_file .payment_input_choose {
  border: 0 !important;
  width: 48%;
}
.payment_form .attach_file {
  margin: 15px 0;
  display: flex;
}
.payment_form .send_payment {
  padding: 10px 0;
  margin: 10px 0 10px;
  background-color: #0e2e7d;
  color: #fff !important;
  width: 100%;
  text-transform: capitalize !important;
}
.payment_form .progress_text a {
  color: #0e2e7d;
  font-weight: 500;
  font-size: 13px;
}
.payment_form .send_payment:hover {
  background-color: #000;
  width: 100%;
}
.attach_file .input_file {
  color: rgba(224, 224, 224, 1);
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 0;
}
.attach_file {
  position: relative;
}
.attach_file .attach_pdf {
  font-size: 14px;
  position: absolute;
  top: 13px;
  left: 15px;
  white-space: nowrap;
  width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attach_file .direct_button {
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 0;
  color: rgba(0, 0, 0, 0.87) !important;
  text-transform: capitalize;
}
.attach_file .direct_deposit {
  font-size: 14px;
  position: absolute;
  top: 13px;
  left: 45px;
}

.attach_file .input_file input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: none !important;
}
.inner_checkbook {
  border: 2px solid #0e2e7d;
  padding: 20px;
  border-radius: 10px;
}
.checkbook_logo {
  height: 50px;
  width: 50px;
}
.inner-section .payment_form_area .credit_form {
  background-color: #fff;
  padding: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-section .payment_form_area .credit_form.img_checkbook.new_market {
  background-color: #fff;
  height: auto;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_icon {
  height: 40px;
}
.modalhead {
  padding: 15px 0;
  font-size: 18px !important;
  text-transform: capitalize !important;
  color: #0E2E7D;
  font-weight: 500!important;
}
.recurring_box .css-1t4vnk2-MuiDialogContent-root {
  border: none !important;
}

.date_input {
  display: flex;
}
.recurring_box p {
  font-size: 14px;
}
.recurring_box label {
  font-size: 14px;
  color:rgba(0, 0, 0, 0.87);
}
.color-label-black{
  color:rgba(0, 0, 0, 0.87);
}
.color-label-blue{
  color: #0E2E7D!important;
}

.recurring_box .recurring_modal_btn {
  border-top: 1px solid rgba(224, 224, 224, 1) !important;
  background: #0e2e7d;
  color: #fff;
  text-transform: capitalize;
  padding: 10px 20px;
}
.recurring_box .recurring_modal_btn:hover {
  background: #000;
  color: #fff;
}

/* send payment checkbook css */
.credit_form div {
  color: #000;
}
.leftmainLogo img {
  width: 60px;
}
.noneed {
  max-width: 675px;
  margin: auto;
  background: linear-gradient(-135deg, rgb(34, 185, 245), rgb(92, 92, 240));
  padding: 3px;
  border-radius: 10px;
  position: relative;
}
.checkbox-box {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
}
.checkbox-box h3 {
  margin: 0;
  text-align: center;
  font-size: 16px;
}
.logoText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logoareandText {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.logoRightText p {
  margin: 0;
}
.chekbokName {
  font-weight: 600;
  margin: 0 0 5px;
}
.cheque-number {
  text-align: right;
  margin: 0 0 5px;
  font-weight: 600;
}
.dateDesopoit span {
  font-weight: 600;
  width: 80px;
  display: inline-block;
  text-align: right;
}
.labelPaye {
  font-weight: 600;
}
.labelPaye.Bottom {
  margin-top: 15px;
}
.dateDesopoit {
  margin: 5px 0;
  text-align: end;
}
.authorizedSign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.siznixocn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.siznixocn img {
  width: 170px;
  display: block;
}
.authoerizesSIgbn {
  margin: 10px 0 0;
}
.authoerizesSIgbn::before {
  content: "";
  background: #000;
  height: 2px;
  width: 160px;
  position: absolute;
  left: -40px;
  right: auto;
  bottom: 22px;
}
.overlayBGimags {
  position: absolute;
  right: 83px;
  opacity: 0.1;
}
.overlayBGimags img {
  max-width: 200px;
}

.account_dollar::before {
  border-bottom: none !important;
}
.account_dollar:focus {
  border-bottom: none !important;
}
.account_dollar option {
  padding: 10px !important;
}
.panelInnerSide .css-19kzrtu {
  padding: 0 !important;
}

.user_bottom {
  border-top: 1px solid #d4d4d4;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  height: 80px;
  padding: 16px !important;
}
.user_bottom .recurring_modal_cancel {
  border-top: 1px solid rgba(224, 224, 224, 1) !important;
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.87);
  text-transform: capitalize;
  padding: 10px 20px;
}

.recurring_box .recurring_modal_cancel:hover {
  background: #0e2e7d;
  color: #fff;
}
.user_modal_input input {
  padding: 10px !important;
  border-radius: 0 !important;
}
.user_modal_input input::placeholder {
  font-size: 14px;
}
.user_modal_input
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #c0c5e1 !important;
  border-width: 1px !important;
}
.user_modal_input input:focus {
  border: 0;
}
.box_area {
  height: 50px;
  background-color: #f7f7fa;
}
.payment_form.payment_header {
  padding: 0 32px 0 5px !important;
}
.payment_form p a {
  color: #0e2e7d;
}
.recipient_style {
  color: #0e2e7d;
  display: flex !important;
  justify-content: end !important;
  align-items: center;
}

.recurring_radio span.css-hyxlzm {
  color: #0e2e7d !important;
}
.dashboard_select fieldset {
  border: none !important;
}
.arrow-left {
  padding-left: 17px !important;
}

.input_a input::placeholder {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0;
}
.input_a fieldset {
  border: none !important;
}
.input_a {
  margin: 15px 0;
  display: flex;
  border: 1px solid rgba(224, 224, 224, 1);
}
.input_a .icon_style_a {
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(224, 224, 224, 1);
  padding: 5px 15px;
}
.input_a .icon_style_a i {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 20px;
  height: 20px;
  color: #0e2e7d;
  font-size: 14px;
}
.darkGreyColor .MuiTableCell-root {
  color: darkgrey !important;
}

.MuiDialogContent-root {
  padding: 0 16px !important;
}
.modal_bluearea p {
  background: #e5f6fd;
  padding: 15px;
  color: #1a94d5 !important;
  border: 1px solid #1a94d5;
  border-radius: 5px;
}
.input_a.style_height input {
  padding: 10px !important;
}
.account_radio span {
  font-size: 14px !important;
}
.modal_greenarea div {
  color: green !important;
  padding: 10px;
  text-transform: uppercase !important;
}
.sign {
  font-size: 20px !important;
  padding: 5px 0 !important;
  font-style: italic;
  color: #000 !important;
  font-weight: 500 !important;
}
.modal_yellowarea p {
  padding: 15px;
  border: 1px solid #ffeeba;
  background: #fff3cd;
  color: #856404 !important;
}
.input_a.style_height .error input {
  border: 1px solid red;
}
.memo_body {
  font-size: 14px;
  width: 100%;
  max-width: 280px;
  word-break: break-all;
}
.error-message {
  color: red;
  padding: 5px 0 10px 0;
}
.modal_redarea p {
  padding: 10px;
  border: 1px solid #ca5d45;
  background: #ca5d45;
  color: #f2efe5 !important;
}

.payment_form .send_many_payment {
  height: 42px;
  padding: 10px 0;
  background-color: #0e2e7d;
  color: #fff !important;
  width: 100%;
  text-transform: capitalize !important;
}
.payment_form .send_many_payment:hover {
  background-color: #000;
  color: #fff;
}
.many_payment{
  height: 100%;
}
.many_payment h4 {
  font-weight: 500;
  color:#0e2e7d;
  font-size: 20px!important;
}
.many_payment h5 {
  font-weight: 500;
  color:#0e2e7d;
  font-size: 16px!important;
}
.payment_form.many_payment .attach_file .payment_input_choose {
  border: 0 !important;
  width: 100%;
}
.many_payment p {
  font-size: 0.875rem;
}
.many_payment .demo_table th,
.many_payment .demo_table th td {
  padding: 0.7rem !important;
}
.payment_form.many_payment .attach_file {
  margin: 5px 0;
  display: flex;
}
.payment_form.many_payment .attach_file .attach_pdf {
  font-size: 14px;
  position: absolute;
  top: 10px;
  left: 15px;
}
.payment_form.many_payment .attach_file .input_file {
  color: rgba(224, 224, 224, 1);
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 0;
  height: 42px;
}
.list-area li.active {
  background: #c0c5e1;
}
.Verifyhead_modal span{
  color: #1a94d5;
  font-size: 0.865rem;
}
.Verifyhead_modal span.name_acme{
  font-style: italic;
  text-transform: uppercase;
}
.verify_button {
  border-top: 1px solid #d4d4d4;
  display: flex !important;
  justify-content: end !important;
  align-items: center;
  height: 80px;
  padding: 16px !important;
}
.input_a_verify input{
  padding: 10px;
}
.input_a_verify input::placeholder {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0;
}
.input_a_verify fieldset {
  border: none !important;
}
.input_a_verify{
  margin: 5px 0;
  display: flex;
  border: 1px solid rgba(224, 224, 224, 1);
}
/*Loader CSS*/
.loader-file{
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vh - 185px);
}
.loaderDiv {
  background: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  top: 0;
  left: auto;
  right: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loaderComponent {
  max-width: 100px;
  position: relative;
}
.spinner-border.text-primary {
  color: #5170fc !important;
  width: 150px;
  height: 150px;
}
.loaderImg {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 80px!important;
  border-radius: 50%;
}
.loader-circle{
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 100px!important;

}
.spinner-border {
  display: inline-block;
  width: 120px;
  height: 120px;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  color: #e6b26e;
}
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}
.loader-compund {
  width: 120px;
  height: 120px;
  margin: auto;
}

.link-style{
  color:#0e2e7d!important;
  font-weight: 500!important;
}
.link-style:hover{
  text-decoration: underline!important;
}
.rtp-ach-buttons{
  padding: 20px 0 10px 0!important;
}
.rtp_button{
  background-color:#0e2e7d!important;
  color:#fff!important;
  width: 100%!important;
  padding: 10px 0!important;
  text-transform: capitalize!important;
}
.ach_button{
  padding: 10px 0!important;
  background-color:#0e2e7d!important;
  color:#fff!important;
  width: 100%!important;
}
.invoice-descript {
  margin: 50px 0 10px 0;
  padding: 20px 10px;
}
.inc{
  font-weight: 600!important;
  color:rgba(0, 0, 0, 0.87)!important;
}
.inc-date{
  font-size: 16px!important;
}
.inner-section .payment_form_area .credit_form.rtp-invoice {
  background-color: #C0C5E1!important;
  padding: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.img-rtp{
  padding: 0!important;
  background-color: #fff;
}

.ach-rtp{
  padding: 0!important;
  background-color: #fff;
}

.img-fluid-sec {
  width: auto;
  height: 400px;
  margin: auto;
  display: flex;
}
.user_bottom.button-end{
  display: flex;
  justify-content:end!important;
}
.ul-section{
  padding: 20px;
  color:#0e2e7d
}
.ul-section li{
  text-decoration: none;
  padding: 0;
  margin: 0 0 10px;
}
.tabs_styleer svg {
  margin-right: 8px !important;
}
.download-pdf{
  color:#0e2e7d;
}
.rtp_button.disableBtn, .ach_button.disableBtn {
  background-color: #2b53b6!important;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
.main-logo-head{
  display: flex;
  height: 40px;
  width: auto;
  align-items: center;
  justify-content: center;
}
.fa-edit{
  color: #0E2E7D;
}
.gray-edit{
  color:#7c8e9c
}
.table_inner_btn .gray-edit:hover{
  color:#fff;
}
.logout-menu {
  left: 4px!important;
  color: rgba(0, 0, 0, 0.87)!important;
  

}
.logout-menu .MuiPaper-root.MuiPaper-elevation{
  border: 1px solid #d4d4d4;
  border-top: none!important;
  color: rgba(0, 0, 0, 0.87)!important;
  box-shadow: none!important;
  border-radius: 0!important;
}
.logout-menu .MuiList-root.MuiList-padding{
padding: 0;
}
.logout-menu .MuiList-root.MuiList-padding li{
  padding: 10px 20px;
}
.logout-menu .MuiList-root.MuiList-padding li:focus{
  background-color: transparent;
}
.logout-menu .MuiList-root.MuiList-padding li:hover{
    background-color: #c0c5e1;
    color: #0E2E7D;
  }
  .recurring_radio {
    margin-bottom: 10px;
  }
  .recurring_radio span{
    font-size: 14px!important;
  }
  .word-wrap{
    width: 100px;
    word-wrap: break-word;
    word-break: break-all!important;
  }
