
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.company_logo {
    height: auto;
    width: 120px;
    margin-bottom: 65px;
    box-shadow: 0 0 10px #E5B36D;
}
.login-body {
	background: #e4d5c1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
}
.login-body .eyeslash i {
    position: relative;
    left: 124px;
    text-align: end;
    top: -28px;
}
h1 {
	font-weight: bold;
	margin: 0;
}
h2{
	text-align: center;
	padding-bottom: 10px;
}
.p {
    color: #fff!important;
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 0px 0 41px!important;
}
span {
	font-size: 12px;
}
a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.login-body .button {
	margin-top: 20px;
	border-radius: 25px !important;
	border: 1px solid transparent  !important;
	background: #0E2E7D!important;
	color: #FFFFFF !important;
	font-size: 12px!important;
	font-weight: bold!important;
	padding: 12px 45px!important;
	letter-spacing: 1px!important;
	text-transform: uppercase!important;
	transition: transform 80ms ease-in!important;
}

.login-body .button:active {
	transform: scale(0.95);
}

.login-body .button:focus {
	outline: none;
}

.ghost {
	background: transparent;
	border-color: #FFFFFF;
}
.login-body .button.ghost.sign-up{
	background: #e4d5c1!important;
	color: #2c3d4d!important;
	border-color: #2c3d4d!important;
}

.login-body .form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}
.login-body fieldset{
	border:none!important;
}
.login-body .input_field input::placeholder{
	font-size: 14px!important;
}
.login-body .input_field input {
	background-color: #eee!important;
	border: none!important;
	padding: 9px 15px!important;
	width: 100%!important;
}
.login-body .input_field input:focus{
	color: #222!important;
	border-width: #222!important;
	border-color:#222!important;
}
.login-body .container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.login-body .form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.login-body .sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.login-body .container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.login-body .sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.login-body .container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.login-body .overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.login-body .container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}
.login-body .overlay {
	background: #0E2E7D;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}
.login-body .container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.login-body .overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.login-body .overlay-left {
	transform: translateX(-20%);
}

.login-body .container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.login-body .overlay-right {
	right: 0;
	transform: translateX(0);
}

.login-body .container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.login-body .social-container {
	margin: 20px 0;
}

.login-body .social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

